function getDescription(context) {
  if (context && context.custom.alt) {
    return context.custom.alt;
  }
  return "Casa Romero Artwork";
}

export default pics =>
  pics
    .map(
      ({ public_id, context }) =>
        `
  <figure class="no-margin">
      <img
        srcset="
          https://res.cloudinary.com/codefinity/image/upload/f_auto/w_768,ar_16:9,c_fill,dpr_auto/v1575060431/${public_id}   768w,
          https://res.cloudinary.com/codefinity/image/upload/f_auto/w_1200,ar_16:9,c_fill,dpr_auto/v1575060431/${public_id} 1200w,
          https://res.cloudinary.com/codefinity/image/upload/f_auto/w_1920,ar_16:9,c_fill,dpr_auto/v1575060431/${public_id} 1920w
        "
        sizes="(min-width: 768px) 1200px, (min-width: 1200px) 1920px"
        src="https://res.cloudinary.com/codefinity/image/upload/f_auto/w_auto,ar_16:9,c_fill,dpr_auto/v1575060431/${public_id}"
        alt="${getDescription(context)}"
      />
    </figure>
  `
    )
    .join(" ");
