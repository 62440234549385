export default {
  menu: {
    appetizers: {
      items: [
        {
          name: "Chicken drummies",
          description:
            "Ten chicken drummies deep fried and topped with our homemade buffalo sauce or with a side of our homemade honey mustard sauce.",
          price: "$12.99"
        },
        {
          name: "Queso fundido",
          description: "Queso topped with chorizo.",
          price: "$7.99"
        },
        {
          name: "Queso Flameado",
          description:
            "Shredded white cheese topped with Mexican chorizo, bell peppers onions, and tomatoes on a hot iron skillet, and served with tortillas.",
          price: "$8.99"
        },
        {
          name: "Sample Platter",
          description:
            "Four chicken drummies, with buffalo sauce, or with a side of our homemade honey mustard sauce. Waffle fries, five garlic shrimp, a side of our deep-fried corn nuggets, chicken flautas, and with our Romero queso dip.",
          price: "$15.99"
        },
        {
          name: "Fiesta Quesadilla",
          description:
            "Two flat flour tortillas with melted cheese and your choice of ground beef or shredded chicken. Served with lettuce, sour cream, and pico de gallo.",
          price: "$11.99"
        },
        {
          name: "Club Quesadilla",
          description:
            "12-inch wheat tortilla stuffed with our season grilled chicken, lettuce, guacamole, pico de gallo, bacon and sprinkle cotija queso, served with a choice of rice, beans or French fries and small side of sour cream.",
          price: "$12.99"
        },
        {
          name: "Chicken Flautas",
          description:
            "Shredded chicken flour tortilla deep fried roll ups, cover on top with our homemade chipotle aioli salsa, corn, cotija cheese, and serve with a side of Spanish rice.",
          price: "$12.99"
        },
        {
          name: "Taquitos Mexicanos",
          description:
            "Shredded chicken or shredded beef corn tortilla deep fried roll ups. Serve with refried beans, Spanish rice, lettuce, pico,de gallo, sour cream, and guacamole.",
          price: "$12.99"
        },
        {
          name: "Nachos Supremos",
          description:
            "Cheese nachos with shredded chicken, ground beef and beans, topped with lettuce, tomatoes, and sour cream",
          price: "$12.99"
        },
        {
          name: "Fajita nachos",
          description:
            "Our famous skirt steak or grilled chicken fajitas served on a bed of crisp nachos and topped with fajita vegetables which include onions, tomatoes, and green peppers. Topped with our cheese sauce.",
          price: "$12.99"
        },
        {
          name: "Fiesta seafood nachos",
          description:
            "Our famous shrimp and scallop fajitas served on a bed of crisp nachos and topped with our mixed vegetables that includes zucchini, yellow squash, red and yellow pepper, broccoli, cauliflower and Romero queso.",
          price: "$13.99"
        }
      ]
    },
    tacoSalads: {
      details:
        "All taco salads are topped with lettuce, tomatoes, sour cream and shredded cheese. All grilled taco salads get a scoop of guacamole.",
      items: [
        {
          name: "Grilled Fajita Steak",
          price: "$12.99"
        },
        {
          name: "Grilled Fajita Chicken",
          price: "$12.99"
        },
        {
          name: "Grilled Fajita Shrimp",
          price: "$12.99"
        },
        {
          name: "Ground Beef",
          price: "$11.99"
        },
        {
          name: "Shredded Chicken",
          price: "$11.99"
        }
      ]
    },
    specialtyStreetTacos: {
      items: [
        {
          name: "Mazatlan Shrimp Tacos",
          description:
            "Three corn tortillas stuffed with seasoned Mexican shrimp, queso fresco, lettuce, pico de gallo and chipotle salsa garnished with cotija cheese. Served with a side of Spanish rice.",
          price: "$13.99"
        },
        {
          name: "Romero Breaded Shrimp Tacos",
          description:
            "Three flour tortillas stuffed with our breaded seasoned shrimp, lettuce, pico de gallo and chipotle salsa garnished with cotija cheese. Served with a side of Spanish rice.",
          price: "$13.99"
        },
        {
          name: "Pollo (Chicken) Al Pastor Tacos",
          description:
            "Three corn tortillas stuffed with alapastor marinated chicken, cilantro, onions, three lime wedges, spicy salsa upon request. Served with a side of Mexican street corn.",
          price: "$12.99"
        },
        {
          name: "Barbacoa (Shredded Beef) Tacos",
          description:
            "Three corn tortillas stuffed with our flavorful shredded beef, queso fresco, a side of spicy salsa, diced onion, cilantro and three lime wedges. Served with a side of Mexican Street corn.",
          price: "$12.99"
        },
        {
          name: "Carnitas (Shredded Pork) Tacos",
          description:
            "Three corn tortillas stuffed with our flavorful shredded pork, diced onion, cilantro and three lime wedges. Served with a side of Mexican street corn and a small side of spicy salsa upon request.",
          price: "$12.99"
        },
        {
          name: "Asada Tacos",
          description:
            "Three corn tortillas stuffed with our flavorful grilled steak, diced onion, cilantro and three lime wedges. Served with a side of Mexican street corn and a small side of spicy salsa upon request.",
          price: "$12.99"
        }
      ]
    },
    dips: {
      items: [
        {
          name: "Romero Dip",
          description: "Cheese dip with ground beef, pico de gallo, and beans.",
          price: "$7.99"
        },
        {
          name: "Roasted Poblano, Corn and Onion Queso Dip",
          price: "$7.99"
        },
        {
          name: "Queso",
          price: "$3.99"
        },
        {
          name: "Guacamole",
          price: "$4.75"
        }
      ]
    },
    sideOrders: {
      items: [
        {
          name: "Chicken or Beef Burrito",
          price: "$3.99"
        },
        {
          name: "Bean Burrito",
          price: "$3.50"
        },
        {
          name: "Chile Relleno",
          price: "$4.75"
        },
        {
          name: "Tamale",
          price: "$4.75"
        },
        {
          name: "Chicken or Ground Beef Enchilada",
          price: "$2.75"
        },
        {
          name: "Beef or Chicken Taco",
          price: "$2.25"
        },
        {
          name: "Mexican Sweet Corn Cake",
          description: "Available for dinner only!",
          price: "$4.99"
        },
        {
          name: "Chicken or Beef Burrito",
          price: "$3.99"
        }
      ]
    },
    "A La Carte": {
      items: [
        {
          name: "16oz Cup of Salsa with Chips",
          price: "$7.99"
        },
        {
          name: "Beans",
          price: "$3.99"
        },
        {
          name: "Rice",
          price: "$3.99"
        },
        {
          name: "French Fries",
          price: "$3.99"
        },
        {
          name: "Pico de Gallo",
          price: "$2.25"
        },
        {
          name: "Pickled Jalapeños",
          price: "$2.75"
        },
        {
          name: "Sour Cream",
          price: "$2.50"
        },
        {
          name: "Shredded Cheese",
          price: "$2.50"
        },
        {
          name: "Corn or Flour Tortillas",
          price: "$2.25"
        },
        {
          name: "Grilled Jalapeños",
          price: "$2.99"
        }
      ]
    },
    platosDePollo: {
      items: [
        {
          name: "Arroz con pollo",
          description:
            "Grilled, diced chicken served on a bed of rice, and sautéed vegetables, smothered in our cheese sauce. Served with lettuce, guacamole, sour cream and pico de gallo.",
          price: "$13.99"
        },
        {
          name: "Chori pollo",
          description:
            "Grilled chicken breast smothered with cheese sauce and topped with chorizo on a iron hot skillet with fajita vegetables on bottom. Served with rice, beans, lettuce, sour cream, and tortillas.",
          price: "$14.50"
        },
        {
          name: "Pollo zarape",
          description:
            "Grilled, diced chicken on a bed of rice topped with our creamy cheese sauce.",
          price: "$12.99"
        },
        {
          name: "Pollo Jamaica",
          description:
            "A grilled chicken breast topped with onions, mushrooms, and cheese sauce. served with Spanish rice, lettuce, tomatoes, sour cream and tortillas.",
          price: "$13.99"
        },
        {
          name: "Chicken with shrimp",
          description:
            "Chicken breast topped with onions, mushrooms, green peppers and five jumbo shrimp on a hot iron skillet. Served with rice, beans, lettuce, sour cream, and tortillas.",
          price: "$15.99"
        },
        {
          name: "Pollo Palapa",
          description:
            "Grilled chicken breast smothered in a layer of melted cheese sauce with our mild relleno salsa. Served with Spanish rice, beans, lettuce, sour cream and tortillas.",
          price: "$14.50"
        },
        {
          name: "Pollo Parrilla",
          description:
            "Grilled chicken breast smothered in our special mild relleno salsa with fajita vegetables on bottom of a hot iron skillet. Served with rice, beans, lettuce, sour cream, and tortillas.",
          price: "$12.99"
        },
        {
          name: "Pollo Jalisco",
          description:
            "Chicken breast marinated in our special adobo salsa, breaded and cooked on a griddle to crispy perfection. served with rice, beans, pico de gallo, tortillas, Romero queso dip on a bed of crispy corn tortillas.",
          price: "$13.99"
        }
      ]
    },
    ribeyeSkirtSteakOrPorkDishes: {
      items: [
        {
          name: "Pollo Encremado",
          description:
            "Grilled, seasoned chicken strips sautéed with mushrooms and a combination of special guajillo and ancho mole salsa mix with sour cream.",
          price: "$13.99"
        },
        {
          name: "Steak Mexicano",
          description:
            "Ribeye steak cooked to order and smothered with sautéed onions, tomatoes and green peppers. Served with Spanish rice, beans, lettuce, pico de gallo, guacamole and tortillas.",
          price: "$15.99"
        },
        {
          name: "Spicy Steak Ranchero",
          description:
            "Ribeye steak smothered with our guajillo ancho sauce served with Spanish rice, beans and tortillas.",
          price: "$15.99"
        },
        {
          name: "Romero Steak Poblano",
          description:
            "Ribeye steak topped with peppers, onions, and cheese sauce. served with Spanish rice, beans, lettuce, guacamole, pico de gallo and tortillas.",
          price: "$15.99"
        },
        {
          name: "Chile Colorado",
          description:
            "Skirt steak chunks or grill chicken cook with our guajillo ancho salsa. Serve with refried beans, Spanish rice, and tortillas.",
          price: "$12.99"
        },
        {
          name: "Carnitas",
          description:
            "Tender pork tips with sautéed onions on top, served with Spanish rice and beans. Garnished with lettuce, guacamole, pico de gallo and tortillas.",
          price: "$12.99"
        },
        {
          name: "Carne Asada",
          description:
            "Skirt steak cooked to order with sautéed onions on top. served with Spanish rice, beans, lettuce, guacamole, pico de gallo and tortillas.",
          price: "$13.99"
        }
      ]
    },
    fajitas: {
      details:
        "All fajitas are served with Spanish rice, beans, lettuce, sour cream, and tortillas. Shrimp fajitas and parrillada fajitas are served with mixed vegetables which consist of yellow squash, zucchini, red and yellow pepper, broccoli and cauliflower.",
      items: [
        {
          name: "Steak",
          description: null,
          price: "$14.99"
        },
        {
          name: "Chicken",
          description: null,
          price: "$14.99"
        },
        {
          name: "Chicken & Steak",
          description: null,
          price: "$15.99"
        },
        {
          name: "Steak, Chicken, & Shrimp)",
          description: null,
          price: "$16.99"
        },
        {
          name: "Shrimp",
          description: null,
          price: "$16.99"
        },
        {
          name: "Parrillada",
          description:
            "A large skillet filled with grilled chicken, beef, shrimp, scallops, pork tips, and sauteed vegetables. Served with rice, beans, lettuce, and sour cream.",
          price: "$33.99"
        },
        {
          name: "Sizzling Alambre",
          description:
            "Grilled chicken, skirt steak, chorizo and bacon topped with queso dip. Served with Spanish rice, beans, lettuce, sour cream and tortillas.",
          price: "$15.99"
        },
        {
          name: "Fajitas con Hongos",
          description:
            "Skirt steak, grilled chicken and mushrooms topped with shredded cheese. served with Spanish rice, beans, lettuce, sour cream and tortillas.",
          price: "$14.99"
        },
        {
          name: "Fajitas del Pacifico",
          description:
            "A fajita platter loaded with tilapia, crab, shrimp and succulent mixed vegetables, all dripping with special habanero pesto spicy salsa. served with Spanish rice, refried beans, lettuce, sour cream and tortillas.",
          price: "$15.99"
        },
        {
          name: "Fajitas Jalisco",
          description:
            "A breaded chicken breast marinated with our spicy adobo salsa, served with fajita vegetables, on bottom of hot sizzling skillet topped with our Romero spinach queso sauce, pico de gallo, and crumbled cotija cheese. Served with rice, beans, lettuce, sour cream and tortillas.",
          price: "$14.99"
        }
      ]
    },
    mariscos: {
      items: [
        {
          name: "Tequila Fiesta Shrimp",
          description:
            "Jumbo shrimp sautéed with garlic butter and a mixture of veggies, with just a hint of tequila. Served with Spanish rice, beans, lettuce, sour cream and tortillas.",
          price: "$13.99"
        },
        {
          name: "Fish Tacos",
          description:
            "Three grilled or beer battered fish tacos, topped with lettuce, pico de gallo, chipotle mayo aioli sauce and a sprinkle of cotija cheese. Served with a side of Spanish rice.",
          price: "$12.99"
        },
        {
          name: "Pescado Costeno",
          description:
            "Grilled tilapia served over a bed of rice, sautéed veggies, lettuce, guacamole, and pico de gallo, topped with our creamy pesto habanero salsa.",
          price: "$12.99"
        },
        {
          name: "Spicy Veracruz Shrimp",
          description:
            "Big jumbo shrimp sautéed with our lemon garlic butter, fresh grilled jalapenos, crispy bacon, Served with rice, beans and tortillas.",
          price: "$13.99"
        },
        {
          name: "Camarones con Arroz",
          description:
            "Jumbo shrimp sautéed with mixed veggies served on a bed of rice with guacamole salad and topped with cheese sauce.",
          price: "$13.99"
        },
        {
          name: "Fiesta Camaron",
          description:
            "Jumbo shrimp sautéed with mixed veggies. Served on a bed of rice, smothered in our cheese sauce.",
          price: "$13.99"
        },
        {
          name: "Camaronés a la Diabla",
          description:
            "Jumbo shrimp cooked in our chile guajillo, ancho salsa. Served with lettuce, pico de gallo and Spanish rice.",
          price: "$12.99"
        },
        {
          name: "Steak and Shrimp",
          description:
            "A juicy ribeye steak with five jumbo shrimp cooked to order, topped with Romero queso sauce, sautéed onions, green peppers and mushrooms served on a hot iron skillet with tortilla strips on bottom. Served with Spanish rice, beans, lettuce, sour cream and tortillas.",
          price: "$16.99"
        }
      ]
    },
    burritos: {
      items: [
        {
          name: "Burrito Oaxaca",
          description:
            "Tortilla overflowing with grilled chicken and skirt steak and beans sautéed in our spicy adobo sauce. Topped with Romero queso sauce, pico de gallo, and sprinkle of cotija cheese. Served with Spanish rice, lettuce, and sour cream.",
          price: "$13.99"
        },
        {
          name: "Burrito Monterey",
          description:
            "Flour tortilla filled with skirt steak chorizo, topped with creamy cheese sauce, sour cream and pico de gallo. Served with rice and beans.",
          price: "$12.50"
        },
        {
          name: "Burrito de Chile Colorado",
          description:
            "A flour tortilla stuffed with diced skirt steak topped with our Colorado salsa and cheese dip. Served with refried beans and rice.",
          price: "$12.50"
        },
        {
          name: "Burrito Mexicano",
          description:
            "A flour tortilla stuffed with shredded pork, onions, green peppers and tomatoes. Topped with our burrito salsa and creamy cheese sauce, lettuce, guacamole, tomatoes, and shredded cheese. Served with Spanish rice and beans.",
          price: "$12.50"
        },
        {
          name: "Steak",
          description:
            "A flour tortilla filled with skirt steak, and topped of with our salsa verde and creamy cheese sauce. served with rice and refried beans.",
          price: "$12.50"
        },
        {
          name: "Burrito Especiál",
          description:
            "A flour tortilla stuffed with your choice of ground beef or shredded chicken, topped of with our burrito mild salsa, lettuce, sour cream, tomatoes and shredded cheese. Served with rice and beans.",
          price: "$12.50"
        },
        {
          name: "Burrito Romero",
          description:
            "Burrito stuffed with seasoned, grilled chicken, smothered with creamy cheese sauce and a chile guajillo, ancho salsa. Served with sour cream, refried beans and Spanish rice.",
          price: "$12.50"
        },
        {
          name: "Sizzling Chicken and Shrimp",
          description:
            "A large 12-inch wheat tortilla stuffed with Mexican rice, refried beans, seasoned mixed veggies, chicken and shrimp, topped with our creamy habanero pesto salsa. Finished with a drizzle of sour cream, roasted corn and cotija cheese, and served over fajita veggies on a hot iron skillet.",
          price: "$14.99"
        },
        {
          name: "Fajita Grande Burrito",
          description:
            "Grilled chicken or skirt steak with tomatoes, green peppers and onions. Served with lettuce, sour cream, rice, and beans. Topped with our mild burrito salsa and cheese sauce.",
          price: "$13.99"
        }
      ]
    },
    chimichangas: {
      details:
        "All chimichangas are served with Spanish rice, beans, lettuce, and sour cream.",
      items: [
        {
          name: "Ground Beef",
          price: "$11.99"
        },
        {
          name: "Shredded Chicken",
          price: "$11.99"
        },
        {
          name: "Grilled Chicken or Steak Fajita",
          price: "$12.99"
        },
        {
          name: "Shrimp Fajita",
          price: "$13.99"
        },
        {
          name: "Shredded beef (Barbacoa)",
          price: "$11.99"
        },
        {
          name: "Vegetable",
          price: "$10.99"
        }
      ]
    },
    enchiladas: {
      items: [
        {
          name: "Trio Chicken Enchiladas",
          description:
            "3 chicken enchiladas, 3 unique flavors: one with special recipe green salsa, the next with our New Mexico red salsa made with guajillo and ancho peppers, and the third gets our flavorful heavy cream pesto salsa. Served with rice and beans.",
          price: "$12.50"
        },
        {
          name: "Enchiladas Mexicanas",
          description:
            "Three cheese enchiladas topped with shredded pork, tomatoes, onions, green pepper, and mole sauce. Served with Spanish rice and beans.",
          price: "$12.50"
        },
        {
          name: "Spinach",
          description:
            "Three flour tortillas filled with our fresh spinach mixture, that consists of spinach, pico de gallo, mushrooms, and corn kernels. Served with refried beans and Spanish rice.",
          price: "$12.50"
        },
        {
          name: "Enchiladas Supremas",
          description:
            "Three enchiladas (beef, chicken, cheese) served with Spanish rice, beans, lettuce, tomatoes and sour cream.",
          price: "$12.50"
        },
        {
          name: "Enchiladas Suizas",
          description:
            "Three chicken enchiladas topped with our creamy cheese sauce then our tomatillo salsa, sour cream, pico de gallo and cotija cheese. Served with Spanish rice and beans.",
          price: "$12.50"
        },
        {
          name: "Baja Shrimp and Crab Enchiladas",
          description:
            "Two flour tortillas stuffed with shrimp and crab, mixed with corn and pico de gallo.",
          price: "$13.99"
        }
      ]
    },
    quesadillas: {
      details:
        "All quesadillas are served with lettuce, sour cream, rice and beans.",
      items: [
        {
          name: "Quesadilla Texana",
          description:
            "Stuffed with spinach, pico de gallo, corn kernels, shredded cheese, mushrooms, and shredded chicken.",
          price: "$12.50"
        },
        {
          name: "Grilled Chicken or Steak Fajita",
          price: "$12.50"
        },
        {
          name: "Ground Beef or Shredded Chicken",
          price: "$10.99"
        },
        {
          name: "Fajita Shrimp",
          price: "$12.99"
        },
        {
          name: "Vegetable",
          price: "$11.99"
        }
      ]
    },
    platters: {
      items: [
        {
          name: "Special Dinner",
          description:
            "Taco, chile relleno, tamale and enchilada. Served with Spanish rice and beans.",
          price: "$13.99"
        },
        {
          name: "Combination",
          description:
            "Choose 2 or 3: Tamale, chile relleno, enchilada, burrito, taco, mini chimichanga. Choose your meat (shredded chicken or ground beef) tamales are only pork and chile rellenos are only cheese. All served with Spanish rice and beans. Substitute cheese dip for salsa for an extra $1.00 on items that get salsa on top.",
          price: "$9.99 (Pick 2) or $10.00 (Pick 3)"
        },
        {
          name: "Romero Platter",
          description:
            "One chicken mole enchilada, one small mini shredded beef chimichanga and one soft breaded shrimp taco. Served with refried beans and Spanish rice.",
          price: "$13.99"
        }
      ]
    },
    "Kids' Menu": {
      items: [
        {
          name: "Cheeseburger & Fries",
          price: "$6.99"
        },
        {
          name: "Chicken Fingers",
          price: "$6.99"
        },
        {
          name: "Cheese Pizza",
          price: "$6.99"
        },
        {
          name: "Taco, Rice and Beans",
          price: "$6.50"
        },
        {
          name: "Cheese Quesadilla, Rice and Beans",
          price: "$6.50"
        },
        {
          name: "Cheese Enchilada, Rice and Beans",
          price: "$6.50"
        },
        {
          name: "Burrito, Rice and Beans",
          description: "Ground Beef or Shredded Chicken",
          price: "$6.50"
        }
      ]
    },
    lunchSpecials: {
      details: "Mon-Fri 11-3",
      items: [
        {
          name: "L1",
          description:
            "Speedy Gonzales: Taco, Enchilada and Spanish Rice or Beans",
          price: "$8.99"
        },
        {
          name: "L2",
          description: "Beef or Chicken Burrito with Rice and Beans",
          price: "$8.99"
        },
        {
          name: "L3",
          description: "Tamale with Rice and Beans",
          price: "$8.99"
        },
        {
          name: "L4",
          description: "Beef or Chicken Taquitos Mexicanos with Rice and Beans",
          price: "$8.99"
        },
        {
          name: "L5",
          description: "Beef or Chicken Enchilada with Rice and Beans",
          price: "$8.99"
        },
        {
          name: "L6",
          description: "Chile Relleno with Rice and Beans",
          price: "$8.99"
        }
      ]
    },
    desserts: {
      items: [
        {
          name: "Deep Fried Ice Cream",
          price: "$6.99"
        },
        {
          name: "Sopapillas",
          description: "Served with a Scoop of Ice Cream",
          price: "$7.99"
        },
        {
          name: "Cheese Cake Chimichanga",
          price: "$7.99"
        }
      ]
    }
  },
  pics: {
    food: [],
    location: []
  }
};
