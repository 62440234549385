export default () => {
  const carousel = document.querySelector("#carousel");
  const menu = document.querySelector("#menu");
  const menuBtns = menu.querySelectorAll(".fas");

  let currentCarouselTranslateX = 0;
  let currentMenuTranslateX = 0;
  let maxCarouselTranslateX =
    -(document.querySelectorAll("#carousel figure").length - 1) * 100;
  let maxMenuTranslateX = -(
    (document.querySelectorAll("#menu > .flex div").length - 1) *
    100
  );

  /**
   * When menu is '2-up',
   * cut maxMenuTranslateX in half,
   * but make sure that it's rounded up to the nearest 100.
   */
  if (innerWidth >= 667) {
    // Use Math.ceil to 'round up' for negative number.
    maxMenuTranslateX = Math.ceil(maxMenuTranslateX / 2 / 100) * 100;
  }

  menuBtns.forEach(menuBtn =>
    menuBtn.addEventListener("click", function() {
      // Remove "vw" and work with a number
      currentMenuTranslateX = Number.parseInt(currentMenuTranslateX);
      currentMenuTranslateX += this.classList.value.endsWith("right")
        ? -100
        : 100;

      /**
       * If currentMenuTranslateX gets below maxMenuTranslateX value,
       * set it back to 0.
       *
       * But, if currentMenuTranslateX gets below 0,
       * If it is, set it to maxMenuTranslateX
       */
      if (currentMenuTranslateX < maxMenuTranslateX) {
        currentMenuTranslateX = 0;
      } else if (currentMenuTranslateX > 0) {
        currentMenuTranslateX = `${maxMenuTranslateX}vw`;
      } else {
        currentMenuTranslateX = `${currentMenuTranslateX}vw`;
      }

      menu.style.setProperty("--menu-page-translate-x", currentMenuTranslateX);
    })
  );

  setInterval(() => {
    currentCarouselTranslateX = Number.parseInt(currentCarouselTranslateX);
    currentCarouselTranslateX -= 100;

    currentCarouselTranslateX =
      currentCarouselTranslateX <= maxCarouselTranslateX
        ? 0
        : `${currentCarouselTranslateX}vw`;

    carousel.style.setProperty(
      "--carousel-translate-x",
      currentCarouselTranslateX
    );
  }, 3000);
};
