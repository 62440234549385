import { startCase } from "lodash";

function generateDescriptionPrice(item) {
  if (item.description) {
    return `<p>${item.description}&nbsp;<span>${item.price}</span></p>`;
  }
  return `<p><span>${item.price}</span></p>`;
}

function generateMenu(m) {
  return Object.entries(m)
    .map(entry => {
      return `
      <div>
        <section>
          <h3>${startCase(entry[0])}</h3>
          ${generateMenuInner(entry[1])}
        </section>
      </div>
    `;
    })
    .join("");
}

function generateMenuInner(menuInfo) {
  let html = "";
  if (menuInfo.details) {
    html += `<p>${menuInfo.details}</p>`;
  }
  html += menuInfo.items
    .map(
      item => `
    <h4 class="no-margin">${item.name}</h4>
    ${generateDescriptionPrice(item)}
  `
    )
    .join("");

  return html;
}

export default menu => `
    ${generateMenu(menu)}
`;
