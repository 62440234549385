export default () => `
<footer>
  <button class="button button--cta chownow-order-online">Order Online!</button>
  <p class="no-margin">&copy; 2021 Casa Romero Mexican</p>
  <small>
    Photos and Site by:
    <a href="https://codefinity.tech/" target="_blank">CodeFinity</a>
  </small>
</footer>
`;
