function getDescription(context) {
  if (context && context.custom.alt) {
    return context.custom.alt;
  }
  return "";
}

function getCaption(context) {
  if (context) {
    return context.custom.caption;
  }
  return "😋";
}

function getPrice(context) {
  if (context && context.custom.price) {
    return `<small>${context.custom.price}</small>`;
  }
  return "";
}

export default pics =>
  pics
    .map(
      ({ public_id, context }) => `
    <div>
    <figure class="flex flex--column">
      <img
        src="https://res.cloudinary.com/codefinity/image/upload/f_auto/w_768,h_550,ar_16:9,c_fill,dpr_auto/v1570655883/${public_id}"
        alt=""
      />
      <figcaption>
      <h3>${getCaption(context)}</h3>
       <p>${getDescription(context)} ${getPrice(context)}</p>
      </figcaption>
    </figure>
  </div>
`
    )
    .join(" ");
