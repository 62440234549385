import logo from "url:~logo.svg";

export default () => `
<header
      class="align-items-center flex flex--column flex--row-md justify-content-space-between-md"
    >
      <h1>Casa Romero Mexican Restaurant - Waterloo, IL</h1>
      <img
        src="${logo}"
        alt="Casa Romero Mexican Restaurant Logo with a 'bad hombre,' Mexican flag colors and cactus graphic."
        class="logo"
      />
      <div class="align-items-center flex flex--column">
        <address>
          <p>
            <a
              href="https://www.google.com/maps/preview#!q=casa+romero+mexican&amp;data=!1m4!1m3!1d3766!2d-90.147491!3d38.331192!4m15!2m14!1m13!1s0x87d8a2b37391ea1f%3A0x7bdd3585f714a685!3m8!1m3!1d4279!2d-90.147491!3d38.331192!3m2!1i1680!2i1050!4f13.1!4m2!3d38.331192!4d-90.147491"
              target="_blank"
            >
              <span class="fas fa-map-pin">&nbsp;</span>512 S. Market St.<br />
              Waterloo, IL 62298
            </a>
          </p>

          <p>
            <a href="tel:6189396440">
              <span class="fas fa-phone">&nbsp;</span>618-939-6440
            </a>
          </p>
        </address>

        <a class="button button--cta chownow-order-online" href="https://direct.chownow.com/order/12834/locations/17983" target="_blank">
          Order Online!
        </a>

        <div class="flex align-items-center">
          <a href=" https://apps.apple.com/us/app/casa-romero/id1477221926?app"><img src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg" alt="Download_on_the_App_Store_Badge">
          </a>

          <!-- Shitty markup from Google App Store -->
          <a href='https://play.google.com/store/apps/details?id=com.chownow.casaromero&hl=en_US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png' class="app-icon" />
          </a>
        </div>
      </div>
    </header>
`;
