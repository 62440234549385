import { GetHungry, Menu, Carousel } from "./sections";

export default st => `
<main>
  <section id="get-hungry">
    <h2 class="no-margin">Popular Menu Items</h2>

      <ul>
        <li class="display-inline-block chownow-order-online"><a href="https://direct.chownow.com/order/12834/locations/17983" target="_blank">Order Online</a></li>
        <li class="display-inline-block"><a href="tel:6189396440">
        Order by ☎: 618-939-6440
      </a></li>
      </ul>

    <div class="grid-wrapper">
      ${GetHungry(st.pics.food)}
    </div>
  </section>

  <section id="menu">
    <h2 class="align-items-center flex justify-content-space-between">
      <span class="fas fa-chevron-circle-left">&nbsp;</span>
      <span>Food Menu</span>
      <span class="fas fa-chevron-circle-right">&nbsp;</span>
    </h2>

    <div class="flex menu-items-wrapper">
      ${Menu(st.menu)}
    </div>
    <!-- <footer class="flex justify-content-space-between">
      <span class="fas fa-chevron-circle-left fa-3x">&nbsp;</span>
      <span class="fas fa-chevron-circle-right fa-3x">&nbsp;</span>
    </footer> -->
  </section>

  <div id="carousel" class="flex">
    ${Carousel(st.pics.location)}
  </div>
</main>
`;
