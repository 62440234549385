import { Banner, Header, Main, Footer } from "./components";
import * as state from "./store";

import scripts from "./scripts";

function render(state) {
  document.querySelector("#root").innerHTML = `
  ${Banner()}
    ${Header()}
    ${Main(state.Main)}
    ${Footer()}
  `;
  scripts();
}

Promise.all([
  fetch(
    `https://res.cloudinary.com/${process.env.CLOUD_NAME}/image/list/food.json`
  )
    .then(response => response.json())
    .then(results => {
      state.Main.pics.food = results.resources;
    }),
  fetch(
    `https://res.cloudinary.com/${process.env.CLOUD_NAME}/image/list/location.json`
  )
    .then(response => response.json())
    .then(results => {
      state.Main.pics.location = results.resources;
    })
])
  .then(() => render(state))
  .catch(err => console.error(`Error fetching pics! ${err}`));
